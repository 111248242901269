
.headerMenu > li,
.headerMenu > li > a {
  height: 100%;
  display: flex;
  align-items: center;
}
.headerMenu > li > a {
  padding-left: 14px;
  padding-right: 14px;
  text-transform: uppercase;
  font-size: 15px;
}
/* .headerMenu > li > a:hover {
  
} */
.topDown {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px #b0bac554;
  box-shadow: 0px 0px 10px #b0bac554;
}




/*menu*/
.headerMenu > li > ul.subMenu {
  background: #fff;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  top: 79px;
  transform: translate(-10px, -10px);
  border-bottom: 4px solid var(--colorGreen);
  width: 230px;
  list-style: none;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.headerMenu > li > ul.subMenu::before {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0 12px 16px 12px;
  content: "";
  height: 0;
  left: 32px;
  position: absolute;
  top: -15px;
  width: 0;
}
.headerMenu > li:hover > ul.subMenu {
  opacity: 1;
  transform: translate(0) !important;
  visibility: visible;
  z-index: 99;
}
.headerMenu > li > ul.subMenu > li > a {
  width: 100%;
  display: block;
  color: var(--colorPara);
  font-size: 15px;
  padding: 7px 17px;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.headerMenu > li > ul.subMenu > li > a:hover {
  margin-left: 7px;
  color: var(--colorGreen);
}

.overlay {
  height: 100%;
  width: 0% !important;
  position: fixed !important;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  background-color: rgb(42, 51, 121);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 8%;
  width: 100%;
  text-align: center;
  margin-top: 0;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  /* font-size: 36px; */
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: var(--colorBrown);
}
.overlay .closebtn {
  position: absolute;
  top: 24px;
  right: 15px;
  font-size: 17px;
  color: #fff;
}
.destop-none {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .img-fluid.mainLogo {
    width: 100px;
  }
  
  .newslide .slick-slide img {
    display: block;
    width: 49px;
  }
  .destop-none {
    display: block !important;
  }
  .mobMb30{
    margin-bottom: 30px;
  }
  .overlay-content .headerMenu > li > a {
   
    font-size: 18px !important;
   
  }
  
  .topDown {
  
    box-shadow: none;
  }
  .topDown {
    position: relative;
  }
  .topDown .triggerMenu svg {
    width: 24px;
  }
  header {
    padding: 0px 0;
  }
  
  body .topDown {
    padding: 10px 0;
  }
  .img-fluid.mainLogo {
    width: 100%;
  }
  body .upperHeader.pb20 {
    padding-bottom: 13px;
  }
  body .upperHeader.pt20 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .overlay-content .headerMenu {
    display: block !important;
    height: auto !important;
  }
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
  
  
}
.enableMobileMenu {
  width: 100% !important;
}

.overlay-content > ul > li {
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.overlay-content .headerMenu {
  height: auto;
}

.overlay-content .headerMenu > li > a {
  /* font-size: 24px;
  display: block; */
  width: 100%;
}
.overlay-content .headerMenu > li > ul.subMenu {
  width: 100%;
}
.overlay-content .headerMenu > li > ul.subMenu::before {
  top: -6px;
}

.overlay-content .headerMenu > li > ul.subMenu {
  top: 52px;
}
.overlay-content .headerMenu > li > ul.subMenu::before {
  left: inherit;
}
.headerMenu > li > a:hover .arrowDown path,.overlay-content .arrowDown path{
  fill: #fff;
}


.topDown .mainLogo {
  width: 180px;
}

@-webkit-keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.topDown{
  -webkit-animation-name: stickySlideDown;
  animation-name: stickySlideDown;
  animation-duration: 0.3s;
  animation-direction: normal;
}
@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
img.img-fluid.mainLogo {
  max-width: 84px;
}
.topDown .headerMenu {
  height: 50px;
}
.home .mainHeader.transition, .\/about-us .mainHeader.transition,.\/thankyou .mainHeader.transition{
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
  background: transparent;
}


@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .home .mainHeader.transition,.\/gk\/ .mainHeader.transition,.\/about-us .mainHeader.transition{
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: 1;
    background: transparent;
  }
}
/*menu animated*/


.menuTrigger [type="checkbox"]:checked,
.menuTrigger [type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
}
.menuTrigger .menu-icon:checked + label,
.menuTrigger .menu-icon:not(:checked) + label{
  position: fixed;
  top: 33px;
  right: 75px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}
.menuTrigger .menu-icon:checked + label:before,
.menuTrigger .menu-icon:not(:checked) + label:before{
  position: absolute;
  content: '';
  display: block;
  width: 30px;
  height: 20px;
  z-index: 20;
  top: 0;
  left: 0;
  border-top: 2px solid #ececee;
  border-bottom: 2px solid #ececee;
  transition: border-width 100ms 1500ms ease, 
              top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
              height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), 
              background-color 200ms ease,
              transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menuTrigger .menu-icon:checked + label:after,
.menuTrigger .menu-icon:not(:checked) + label:after{
  position: absolute;
  content: '';
  display: block;
  width: 22px;
  height: 2px;
  z-index: 20;
  top: 10px;
  right: 4px;
  background-color: #ececee;
  margin-top: -1px;
  transition: width 100ms 1750ms ease, 
              right 100ms 1750ms ease,
              margin-top 100ms ease, 
              transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menuTrigger .menu-icon:checked + label:before{
  top: 10px;
  transform: rotate(45deg);
  height: 2px;
  background-color: #ececee;
  border-width: 0;
  transition: border-width 100ms 340ms ease, 
              top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
              height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), 
              background-color 200ms 500ms ease,
              transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menuTrigger .menu-icon:checked + label:after{
  width: 30px;
  margin-top: 0;
  right: 0;
  transform: rotate(-45deg);
  transition: width 100ms ease,
              right 100ms ease,  
              margin-top 100ms 500ms ease, 
              transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}

.menuTrigger .nav{
  position: fixed;
  top: 12px;
  right: 50px;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
  box-shadow: 0 8px 30px 0 rgba(0,0,0,0.3);
  background-color: #000;
  animation: border-transform 7s linear infinite;
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),  
              right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
              transform 250ms 1100ms ease,
              width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
              height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
@keyframes border-transform{
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
  14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
  28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
  42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
  56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
  70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
  84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
}

.menuTrigger .menu-icon:checked ~ .nav {
  animation-play-state: paused;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 200%;
  height: 200%;
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),  
              right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),
              transform 250ms 700ms ease,
              width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
              height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
}

.menuTrigger .nav ul{
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: center;
  transform: translateY(-50%);
  list-style: none;
}
.menuTrigger .nav ul li{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 250ms linear;
}
.menuTrigger .nav ul li:nth-child(1){
  transition-delay: 200ms;
}
.menuTrigger .nav ul li:nth-child(2){
  transition-delay: 150ms;
}
.menuTrigger .nav ul li:nth-child(3){
  transition-delay: 100ms;
}
.menuTrigger .nav ul li:nth-child(4){
  transition-delay: 50ms;
}
.menuTrigger .nav ul li a{
  font-size: 9vh;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 800;
  display: inline-block;
  position: relative;
  color: #ececee;
  transition: all 250ms linear;
}
.menuTrigger .nav ul li a:hover{
  text-decoration: none;
  color: #ffeba7;
}
.menuTrigger .nav ul li a:after{
  display: block;
  position: absolute;
  top: 50%;
  content: '';
  height: 2vh;
  margin-top: -1vh;
  width: 0;
  left: 0;
  background-color: #353746;
  opacity: 0.8;
  transition: width 250ms linear;
}
.menuTrigger .nav ul li a:hover:after{
  width: 100%;
}
.menuTrigger .menu-icon:checked ~ .nav  ul li {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease,
              transform 250ms ease;
}
.menuTrigger .menu-icon:checked ~ .nav ul li:nth-child(1){
  transition-delay: 1400ms;
}
.menuTrigger .menu-icon:checked ~ .nav ul li:nth-child(2){
  transition-delay: 1480ms;
}
.menuTrigger .menu-icon:checked ~ .nav ul li:nth-child(3){
  transition-delay: 1560ms;
}
.menuTrigger .menu-icon:checked ~ .nav ul li:nth-child(4){
  transition-delay: 1640ms;
}

@media screen and (max-width: 991px) {
  .menuTrigger .menu-icon:checked + label,
  .menuTrigger .menu-icon:not(:checked) + label{
    right: 30px;
  }

  .menuTrigger .nav{
    right: 15px;
  }
 
 .menuTrigger .nav ul li a{
    font-size: 5vh;
  }
  img.img-fluid.mainLogo {
    max-width: 50px;
  }
}
/*menu animated*/
.topDown .navWrapper.mt10 {
  margin-top: 0px;
}
.lightMode .topDown {
  background: #fff !important;
}
/* .\/thankyou .headerMenu > li > a {
  color: #fff;
}
.\/thankyou .mainLogo img{
  filter: brightness(0) invert(1);
} */

.home .topUp img.img-fluid.mainLogo{
  filter: brightness(0) invert(1);
}
.home .topUp .headerMenu > li > a {
  color: #fff;
} 