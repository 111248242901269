:root {
  --colorBlack: #222222;
  --colorWhite: #fff;
  --colorGrey: #c4c2c2;
  --colorGrey1: #f4f4f4;
  --colorLightBlue: #E3F3FD;
  --colorOrange: #ec7523;
  --colorRed: #ff5757;
  --colorGreen: #55AF4D;
  --colorYellow: yellow;
  --colorPara: #1B2B40BF;
  --colorBlue: #0a3249;
  --colorBrown: #a07f50;
  --colorLightBrown: #a1a1a1;
  --colorPink: #fff7f5;
}
.colorprimary {
  color: var(--colorBlue);
}
.paraGrey{
  color: #888888;
}
.bgPink {
  background: var(--colorPink);
}
.colorBrown{
  color: var(--colorBrown);
}
.bgBrown{
  background: var(--colorBrown)
}
.colorGrey1{
  color: var(--colorGrey1);
}
.colorLightBrown{
  color: var(--colorLightBrown);
}
.bgFullBlack{
  background: #000;
}
body .colorBlack {
  color: var(--colorBlack);
}
.bgLightBlue{
  background: var(--colorLightBlue);
}
.colorWhite {
  color: #fff;
}
.bgBlack {
  background: var(--colorBlack);
}
.bgBlue {
  background: var(--colorBlue) !important;
}
.bgGreen {
  background: var(--colorGreen) !important;
}
body .bgWhite {
  background: #fff;
}
.colorOrange {
  color: var(--colorOrange);
}
.colorPara {
  color: var(--colorPara);
}
.colorBlue {
  color: var(--colorBlue);
}
.colorGreen {
  color: var(--colorGreen);
}
.colorRed {
  color: var(--colorRed);
}
.bgLightOrange {
  background: var(--colorLightOrange);
}
.bgOrange{
  background: var(--colorOrange);
}
.colorGrey {
  color: var(--colorGrey);
}
.bgGrey{
  background: #F6F6F7;
}
body .btnBlack {
  background: var(--colorBlack);
  color: #fff;
}
.bgLightOrange {
  background: var(--colorLightOrange);
}

.fRounder {
  background: rgba(160, 127, 80,0.2);
}