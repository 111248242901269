/*All Mobile*/
@media only screen and (max-width: 767px) {
  body .heroSection {
    height: 70vh;
  }
  .mobPt70{
    padding-top: 70px;
  }
  .visionvidio.newmission {
    margin-top: 88px;
  }
  .visionvidio {
    margin-top: 40px;
  }
  .projectMainList .fs22 {
    font-size: 18px;
  }
  .bgfifty .fs45 {
    font-size: 33px;
  }
  .bgfiftymission .fs45 {
    font-size: 33px;
  }
  .projectMainList .fs40 {
    font-size: 30px;
  }
  body .bigfont{
    display: none;
  }
  body .titles h2 {
    font-size: 20px;
  }
  body .fs60{
    font-size: 48px;
  }
  .projectMainList .fs25 {
    font-size: 18px;
  }
  .whyChsWrp {
    width: 100%;
    height: auto;
    padding: 25px;
    border-radius: 13px;
  }
  header.transition {
    position: sticky;
    top: 10px;
    width: 100%;
    z-index: 9999;
  }
  .newslide .mb20 {
    margin-bottom: 0px;
  }
  .newslide .mt40{
    margin-top: 20px;
  }
  .newslide .btnTheme {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  body .mobMr0 {
    margin-right: 0px;
  }
  body .mobwidth50{
    width: 50%;
  }
  body .mobmb0{
    margin-bottom: 10px;
  }
  body .btnTheme {
    background: #000;
    padding: 12px 22px 12px 22px;
    border-radius: 30px;
    color: #fff;
    line-height: 15px;
    font-size: 15px;
    z-index: 0;
  }

  body .btnMob.btnTheme{
    background: #000;
    border-radius: 3px;
    color: #fff;
    line-height: 15px;
    font-size: 13px;
  }
  body .pt80 {
    padding-top: 40px;
  }
  body .pb80 {
    padding-bottom: 40px;
  }
  body .mobileheight {
    min-height: 358px;
  }
  body .mobmr0{
    margin-right: 0px;
  }
  .mobilereverse {
    flex-direction: column-reverse;
  }
  .mobilereverse .col-lg-6:nth-child(1) {
    margin-top: 15px;
  }
  body .mobmb30{
    margin-bottom: 30px;
  }
  body .mobmr5 {
    margin-right: 5px;
  }
  body .mobFs10 a {
    font-size: 10px;
  }
  body .mobFs10 a svg {
    width: 4px !important;
  }
  body .mobFs10 span {
    font-size: 10px;
  }
  body .wrapperArea {
    padding: 12px;
    border-radius: 7px;
    transition: all 0.5s;
  }
  body body .mobDflex {
    display: flex;
  }
  body .mobPt50 {
    padding-top: 50px;
  }
  body .mobPb50 {
    padding-bottom: 50px;
  }
  .mobColomnReverse {
    flex-direction: column-reverse;
  }
  body .mobJustifyContentBetween {
    justify-content: space-between !important;
  }
  body .mobFs32 {
    font-size: 32px;
  }
  body .mobPt10 {
    padding-top: 10px;
  }
  body .mobPt30 {
    padding-top: 30px;
  }
  body .mobMr10 {
    margin-right: 10px;
  }
  body .mobMb0 {
    margin-bottom: 0px;
  }
  body .mobMr10 {
    margin-right: 10px;
  }
  body .mobMb10 {
    margin-bottom: 10px;
  }
  body .mobMb15 {
    margin-bottom: 15px;
  }
  body .mobMb20 {
    margin-bottom: 20px;
  }
  .mobMb40 {
    margin-bottom: 40px;
  }
  body .mobMt30 {
    margin-top: 30px;
  }
  body .mobJustifyContentStart {
    justify-content: flex-start !important;
  }
  body .mobWidth100 {
    width: 100%;
  }
  body .mobPt40 {
    padding-top: 40px;
  }
  body .mobMl0 {
    margin-left: 0px;
  }
  body .mobPb40 {
    padding-bottom: 40px;
  }
  body {
    overflow-x: hidden;
  }
  .dNoneXs {
    display: none !important;
  }

  body .col-xs-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  body .col-xs-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  body .col-xs-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  body .col-xs-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  body .col-xs-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  body .col-xs-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  body .col-xs-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  body .col-xs-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  body .col-xs-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  body .col-xs-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  body .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

/*All Mobile*/

/*All Small Tablets*/
@media only screen and (max-width: 991px) and (min-width: 768px) {

  body .circleAnimate {
    width: 300px;
    height: 300px;
  }
  
  body .whyChsWrp {
    width: 100%;
  }
  body .bannerF {
    font-size: 11vw;
    line-height: 9vh;
  }
  .caption {
    margin-top: 85px;
  }
  body .swiperThumbnail img {
    width: 98px;
  }
  
  body .titles h2 {
    font-size: 5vw;
    line-height: 4vh;
  }
  body .living {
    font-size: 12vw;
  }
  .mainFooer .fs16 {
    font-size: 14px;
  }
  .mainFooer .fRounder {
    padding-left: 12px;
    padding-right: 12px;
  }
  .newslide .mb20 {
    margin-bottom: 0px;
  }
  .newslide .mt40{
    margin-top: 20px;
  }
  .newslide .btnTheme {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  body .mobJustifyContentStart {
    justify-content: flex-start !important;
  }
  body .mobMb0 {
    margin-bottom: 0px;
  }
  .mobMb15 {
    margin-bottom: 15px;
  }
  body .mobMr10 {
    margin-right: 10px;
  }
  .dNoneMd {
    display: none !important;
  }
}

/*All Small Tablets*/

/*All large Tablets*/
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  body .bigfont{
    display: none;
  }
  header.transition {
    position: sticky;
    // top: 10px !important;
    width: 100%;
    background: #fff;
    z-index: 9999;
  }
  body .tabWidth100 {
    width: 100%;
  }
}


@media only screen and (max-width: 1200px) and (min-width: 768px) {
  body .newone{
    position: static;
  }
  body .slideronenew{
    width: 90%;
    }
    body .whyChsWrp {
      padding-left: 30px;
    }
}

/*All large Tablets*/

/*Only large Tablets*/
@media only screen and (max-width: 1024px) and (min-width: 992px) {
  body .nav.triggerMenu {
    right: 60px;
  }
  body .circleAnimate {
    width: 300px;
    height: 300px;
  }
  body .whyChsWrp {
    width: 100%;
    padding-left: 30px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .dNoneLg {
    display: none !important;
  }
  body .tabLgWidth100 {
    width: 100%;
  }
}

/*Only large Tablets*/

/*All Small Laptops*/
@media only screen and (max-width: 1400px) and (min-width: 1201px) {
}
@media only screen and (min-width: 1200px) {
  .dNoneXl {
    display: none !important;
  }
}
/*All Small Laptops*/
