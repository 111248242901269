.slideronenew .swiper {
    width: 100%;
    height: 100%;
  }
  
  .slideronenew .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  
  /* .swiper-slide {
    width: 60%;
  } */
  
  /* .swiper-slide:nth-child(2n) {
    width: 40%;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 20%;
  } */
  
  .slideronenew .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    align-items: center;
    transition: all 0.5s;
      transition-duration: 0.5s;
      transition-delay: 0s;
  }
  .slideronenew {
    width: 50%;
    margin: 0 auto;
    padding: 15px 0px;
   
  }
  .slideronenew .swiper-slide-active {
    /* transform: scale(1.5) !important; */
    background-color: #ecf7fd;
    z-index: 1;
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
  .slideronenew .swiper-slide-prev {
   
    position: relative;
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
  .slideronenew .swiper-slide-next {
   
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
  /* .slideronenew .swiper-slide-active .box {
    padding: 35px;
  } */


  
  @media only screen and (max-width: 767px) {
    .slideronenew {
      width: 93%;
      margin: 0 auto;
      padding: 15px 0px;
    }
    .whyChsWrp p.fs16 {
      font-size: 14px !important;
    }
  }
  