.bordercontactus {
    border-bottom: 2px solid #ddd;
    position: relative;
    top: -59px;
    z-index: -1;
  }
  .contactusheading {
    background-color: #fff;
    display: inline-block;
    position: relative;
    padding: 0px 15px;
  }
  .contactusheadingdark {
    background-color: #070708;
    display: inline-block;
    position: relative;
    padding: 0px 15px;
    color: var(--colorBrown);
  }
  .newboxshadow {
    box-shadow: 0px 0px 43px 0px #00000085;
    border-radius: 40px;
    position: relative;
    top: 59px;
    z-index: 1;
  }
  .uifrom .title {
    display: none !important;
  }
  .selldof_row {
    width: 49%;
    display: inline-flex;
}
.selldof_row:nth-child(7) {
  width: 98%;
}
.selldof_row:nth-child(8) {
  width: 98%;
}

.w-98 {
  width: 98%;
}

.uifrom {
  background-color: var(--colorBlue);
  padding: 52px;
  border-radius: 16px;
}
.uifrom .selldof .form-control {
  /* border-color: #a07f50;
  background-color: transparent; */
  /* border: none; */
  /* border-bottom: 3px solid #a07f50 !important; */
  /* border-radius: 0px; */
  /* padding: 21px 15px; */
  font-size: 15px;
  box-shadow: none;
  /* height: 35px; */
}
/* .selldo-intl-tel-input.allow-dropdown .selected-flag, .selldo-intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
  display: none;
} */
.uifrom .selldof label {
  color: #fff;
}
.uifrom .selldof .btn, .sell_do_ctc_btn, .selldof .btn, .sell_do_verify_btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 12px 31px;
  font-size: 21px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #a07f50;
  border-color: #a07f50;
  margin-top: 0;
  text-transform: uppercase;
  width: 288px;
  height: 53px;
}

  @media screen and (max-width: 1024px) {

    .contactusheading {
      background-color: #fff;
      display: inline-block;
      position: relative;
      padding: 0px 15px;
    }
    .bordercontactus {
      border-bottom: 1px solid #ddd;
      position: relative;
      top: -18px;
      z-index: -1;
    }
    .uifrom {
      background-color: var(--colorBlue);
      padding: 16px;
      border-radius: 8px;
    }
    .selldof_row {
      width: 100%;
      display: inline-flex;
    }
    .selldof_row:nth-child(7) {
      width: 100%;
    }
  }