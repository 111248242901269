  .itemLightwhite{
    color:#c2c6ca ;
  }
  a.itemLightwhite:hover{
    color:#c2c6ca;
  }
  
  .social a {
    margin-right: 15px;
    font-size: 33px;
  }
  .social a svg {
    fill: #55af4d;
  }
  .social a:hover svg {
    fill: #fff;
  }
  .fooTerLogo {
    /* background-color: #fff;
    padding: 17px;
    border-radius: 5px; */
    width: 90%;
  }
  .whitesvg svg {
    fill: #fff;
  }
footer ul li a:hover{
  color: var(--colorBrown) !important;
}
.cssfont a {
  text-transform: capitalize;
}
.themeInput {
  width: 100%;
  height: 45px;
  background: transparent;
  border: 1px solid #9ABAD7;
  outline: none;
  color: var(--colorWhite);
}
.themeInput:focus {
  border-color: var(--colorGreen);
}
.themeInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}
.themeInput::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.themeInput:-ms-input-placeholder { /* IE 10+ */
 color: #fff;
  opacity: 1;
}
.themeInput:-moz-placeholder { /* Firefox 18- */
 color: #fff;
  opacity: 1;
}
.themeInput::placeholder {
    color: #fff;
     opacity: 1; 
   }
   .fRounder{
    border: 1px solid var(--colorBrown);
    background: rgba(160, 127, 80,0.2);
   }
   .fNav a::before {
    position: absolute;
    content: "";
    background: var(--colorLightBrown);
    width: 7px;
    height: 7px;
    border-radius: 30px;
    display: flex;
    top: 7px;
    left: 0px;
  }
  .colorLightBrown:hover{
    color: var(--colorBrown);
  }
  .viewToggle {
    position: fixed;
    right: 78px;
    bottom: 27px;
    z-index: 1;
  }
  .viewToggle img {
    width: 82px;
  }
  .mainFooer video {
    height: 700px;
    object-fit: cover;
  }
  .mainFooer .fRounder {
    min-height: 230px;
  }
  @media only screen and (max-width:767px) {
     .mainFooer .fRounder {
      min-height: auto;
    }
}