.heroSection {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutus {
  background-image:url(../img/aboutus/aboutus.webp);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgmission{
  background-image:url(../img/aboutus/bgmission.webp);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgfifty {
  background: rgb(42,51,121);
  background: linear-gradient(90deg, rgba(42,51,121,0) 43%, rgb(255, 255, 255) 43%);
}
.bgfiftyBrown {
  background: rgb(42,51,121);
  background: linear-gradient(90deg, rgba(42,51,121,0) 43%, #a07f50 43%);
}
.bgfiftymission{
  background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 58%, rgba(0,0,0,0) 58%);
}
.bgfiftymissionBrown{
  background: rgb(255,255,255);
background: linear-gradient(90deg, #a07f50  58%, rgba(0,0,0,0) 58%);
}
.bgourteam {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 57%, rgba(10,50,73,1) 57%);
}
.colorLight {
  color: #7a7a7a;
}
.w-85 {
  width: 85%;
}
.devider {
  height: 2px;
  width: 259px;
  background-color: #323539;
  margin: 0 auto;
}
.mainabhout {
  position: absolute;
  width: 100%;
  top: 59px;
}
.aboutusnew {
  margin: 0 0 0 auto;
  padding: 50px 15px;
}
.bordernew {
  border-left: 2px solid #ddd;
  padding-left: 26px;
}
.borderBlack {
  border-left: 2px solid #000;
  padding-left: 26px;
}
.secondaboutus {
  margin: 0 0 0 auto;
}
.mainabhoutnew {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
}
.newone {
  position: relative;
  top: 26px;
  z-index: 2;
}
  @media screen and (max-width: 1024px) {

  .videoWrapper video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .devider {
    height: 1px;
    width: 158px;
    background-color: #323539;
    margin-bottom: 25px;
  }
  
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .mainabhout {
    width: 100%;
    top: 16px;
  }
  .mainabhoutnew {
    top: 17px;
  }
}
@media screen and (max-width: 768px) {
.bgourteam {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 40%, rgb(10, 50, 73) 40%);
}
.mainabhout {
  position: relative;
  width: 100%;
  top: 0px;
}
.mainabhoutnew {
  position: relative;
}
.bgfifty {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
}
.bgfiftymission {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
}
.bgfiftyBrown {
  background: rgb(42,51,121);
  background: linear-gradient(90deg, #a07f50 0%, #a07f50 100%);
}
.bgfiftymissionBrown{
  background: rgb(255,255,255);
  background: linear-gradient(90deg, #a07f50 0%, #a07f50 100%);
}
.bgourteam {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(10,50,73,1) 20%);
}
}

@media screen and (max-width: 767px) {

  .mainabhout {
    position: relative;
    width: 100%;
    top: 0px;
  }
  .mainabhoutnew {
    position: relative;
  }
  .bgfifty {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
  }
  .bgfiftymission {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
  }
  .bgfiftyBrown {
    background: rgb(42,51,121);
    background: linear-gradient(90deg, #a07f50 0%, #a07f50 100%);
  }
  .bgfiftymissionBrown{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, #a07f50 0%, #a07f50 100%);
  }
  .bgourteam {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(10,50,73,1) 20%);
  }
}
