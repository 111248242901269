@font-face {
  font-family: heading;
  src: url('./assets/fonts/Nephilm.otf');
}
@font-face {
  font-family: para;
  src: url('./assets/fonts/Sora-VariableFont_wght.ttf');
}

  body,html,li,a,p,div,.roboto{
    font-family: para;
  }
  h1,h2,h3,h4,h5,h6,.barlow,h2 .react-reveal *,h1 .react-reveal *,h3 .react-reveal *,h4 .react-reveal *,h5 .react-reveal *{
    font-family: heading;
  }
.subfont{
  font-family: para;
}
.headingFont{
  font-family: heading;
}
.fontlight{
  font-weight: lighter;
}
.teamImgWrp img {
  border: 4px solid #fff;
  border-radius: 16px;
}
.singleTeam {
  height: 300px;
}
.singleTeam > .container {
  margin-top: 150px;
}
a.line {
  text-decoration: underline !important;
}


.inputTheme {
  border: 1px solid #D6D6D6;
  border-radius: 0px;
  padding: 18px 17px;
  height: 50px;
  transition: all 0.3s ease-in;
  outline: none;
}
.inputTheme:focus {
border-color: #2C367A
}
.fileUploadTheme {
  border: 1px solid #1B2B4026;
  height: 48px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 18px;
}
.fileUploadTheme .chooseFile {
  background: #1B2B40BF;
  color: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  margin-left: 12px;
  font-size: 14px;
}
.tabbStyle {
  border: 1px solid #1B2B4026;
  border-radius: 30px;
}

.tabbStyle button {
  background: transparent;
  border: none;
  padding: 12px 30px 12px 30px;
  border-radius: 30px;
  font-size: 15px;
}
.tabbStyle button.active {
  background: var(--colorGreen);
  color: #fff;
}
.wrapperContact {
  -webkit-box-shadow: 0px 10px 60px #B0BAC554;
  box-shadow: 0px 10px 60px #B0BAC554;
  padding: 40px;
  background: #fff;
}
.formSection {
  margin-top: -50px;
}
/*react select*/
.themeSelect__indicator-separator {
  display: none;
}
.themeSelect__control {
  border: 1px solid #1B2B4026;
  outline: none !important;
}
.themeSelect__option--is-selected {
  background: #000 !important;
}
.themeSelect__option:hover {
  background: var(--colorGreen) !important;
  color: #fff;
}
.themeSelect *,
.themeSelect {
  outline: none !important;
}
.themeSelect__input-container {
  margin-top: -6px !important;
}
.themeSelect__value-container {
  height: 45px;
}
.themeSelect__control--is-focused {
  border-color: #1B2B4026 !important;
  -webkit-box-shadow: 0 0 0 0 var(--colorBlack) !important;
  box-shadow: 0 0 0 0 var(--colorBlack) !important;
}
.select_border div.themeSelect .themeSelect__control {
  border-color: #e8e9e9;
}
.themeSelect__placeholder {
  margin-top: -5px;
}
/*react select*/
.bgBlue.btnTheme:hover {
  background: var(--colorGreen) !important;
}
.bgGreen.btnTheme:hover {
  background: var(--colorBlue) !important;
}
.bgcover{
  background-size: cover;
}
.intro-content-second hr {
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(4,80,140,1) 52%, rgba(4,80,140,1) 100%, rgba(255,255,255,1) 100%);
  border: 0;
  position: relative;
  right: calc(-5.2rem - 15px);
  z-index: 9;
  height: 2.4px;
  opacity: 1;
}

.teamWrapper img {
  -webkit-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
}
.testimonialWrapper {
  min-height: 323px;
}

@media only screen and (max-width: 767px) {
  .swiperThumbnail .swiper-wrapper{
    max-width: 75% !important;
    background-position: right !important;
  }
  .mobilereversevission{
    display: flex;
    flex-direction: column-reverse;
  }
  body .formSection{
    margin-top: 0px;
    padding-top: 30px;
  }
  .Mobileteamimage img {
    width: 50%;
    margin-bottom: 15px;
  }
  .singleTeam > .container {
    margin-top: 55px;
  }
  body .mobileList {
    display: block !important;
  }
  .mobileList li {
    margin-bottom: 15px;
  }
}


.slidermainview {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.flight{
  font-weight: lighter;
}


.customfiled {
  border: none !important;
  border-bottom: 3px solid #b88a4c !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
  background-color: transparent !important;
}
.form-control:focus {
  color: #212529;
  background-color: transparent;
  /* border-color: var(--colorBlue) !important; */
  outline: 0;
  box-shadow: none !important;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background: var(--colorGreen);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--colorGreen);
}

.contentArea ul {
  margin-bottom: 0px;
}
.intro-content-second hr {
  right: calc(0rem - 15px);
}



a {
  color: #0a3249;
}
.validation {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 2px;
}
.bDivi{
  border-bottom: 1px solid #323d4c;
}
.bgcover {
  background-size: cover !important;
  background-repeat: no-repeat !important;

}

.pointer{
  cursor: pointer;
}
body{
  overflow-x: hidden;
}
.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
 }
}

.bannerF {
  font-size: 140px;
  line-height: 110px;
  
}
.btnTheme img {
  width: 20px;
  z-index: 1;
}
.btnTransparent img {
  z-index: 1;
}
.living {
  font-size: 100px;
  line-height: 93px;
}
 
.brownGradient{
  background: #A07F50;
  background: linear-gradient(to top, #A07F50 0%, #ffdfb0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blackGradient{
  background: #C1B3A1;
  background: -webkit-linear-gradient(to right, #C1B3A1 0%, #8E7653 100%);
  background: -moz-linear-gradient(to right, #C1B3A1 0%, #8E7653 100%);
  background: linear-gradient(to right, #C1B3A1 0%, #8E7653 100%);
  background-clip: text;
  -webkit-background-clip: text;
  
  -webkit-text-fill-color: transparent;
   
}
.bgBrownGradient{
background: linear-gradient(to right,  rgba(234,220,202,1) 0%,rgba(179,156,122,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.imgProject{
  z-index: 1;
}
.imgProject::after {
  background: linear-gradient(to right, rgba(234,220,202,1) 0%,rgba(179,156,122,1) 100%);
  content: "";
  position: absolute;
  right: 0px;
  width: 80%;
  height: 100%;
  top: 0px;
  z-index: -1;
}
.imgProject2::after {
  background: linear-gradient(to right, rgba(234,220,202,1) 0%,rgba(179,156,122,1) 100%);
  content: "";
  position: absolute;
  left: 0px;
  width: 80%;
  height: 100%;
  top: 0px;
  z-index: -1;
}
.btnTransparent {
  border: 1px solid var(--colorBrown);
  padding: 15px 20px;
}
.heartAuthor {
  top: 72px;
  right: 42px;
  width: 150px;
  position: absolute;
}
.testimonials {
  margin-left: 18%;
}
.circleAnimate {
  border: 1px solid #bbbbbb;
  border-radius: 100%;
  width: 310px;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 10s linear infinite;
}
/* .circleAnimate::before {
  content: "";
  width: 30px;
  height: 30px;
background: linear-gradient(135deg,  rgba(204,205,205,1) 0%,rgba(129,129,129,1) 100%); 
  position: absolute;
  top: -15px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
} */



.proMaintitle {
  z-index: 1;
  position: relative;
  margin-top: 40px;
  text-align: right;
}
.swiperThumbnail .swiper-slide.swiper-slide-visible {
  width: 110px !important;
  height: auto;
  cursor: pointer;
}
.swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) {
  width: 205px !important;
  margin-top: 25px;
}
.swiperThumbnail .swiper-slide.swiper-slide-visible img{
  transition: transform 0.5s;
}

.swiperThumbnail .swiper-slide.swiper-slide-visible:hover img{
  transform: scale(1.1);
}
.testimonial{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.greyGradient{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cccdcd+0,818181+100 */
background: linear-gradient(135deg,  rgba(204,205,205,1) 0%,rgba(129,129,129,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.swiperThumbnail{

  margin-right: 0px !important;
  margin-bottom: 40px;
}
.swiperThumbnail .swiper-wrapper {
  max-width: 60%;
  flex-wrap: wrap;
  margin-right: 0px !important;
  justify-content: flex-end;
  background: url('./assets/img/home/bg_ly.png');
  background-repeat: no-repeat;
  background-size: contain;

}

.dataTesti {
  position: relative;
  background: url('./assets/img/home/home_frame.png');
  background-size: 90%;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 233px;
  margin-top: 85px;
}
.dotscss .swiper-pagination {
  position: relative;
  top: 13px;
  padding-bottom: 0px;
}
/* .swiperThumbnail .swiper-wrapper img {
  width: 100px;
} */
.mainHeader.transition.topDown {
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0px;
}
.topDown .mainLogo img {
  width: 40px;
}
.topDown .upperHeader {
  padding-top: 6px;
  padding-bottom: 6px;
}
.topDown.darkMode {
  background: #000 !important;
}
.clickToClose:hover {
  color: var(--colorBrown);
}
.whyChsWrp {
  background: #ecf7fd;
  border-radius: 28px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  margin: 0 auto;
  height: 398px;
  display: flex;
  align-items: center;
  text-align: left;
}
.fs15{
  font-size: 15px;
}
li.slide .whyChsWrp {
  width: 53%;
  opacity: 0.5;
}
li.slide.selected .whyChsWrp {
  width: 60%;
  opacity: 1;
}


@media only screen and (min-width: 1200px) {
  .cornerHandle {
    margin-left: 40px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .bannerF {
    font-size: 104px;
    line-height: 89px;
  }
  .caption {
    margin-top: 85px;
  }
  .testimonial p.fs16 {
    font-size: 13px;
  }
  .dataTesti {
    /* width: 45%; */
    padding-top: 174px;
  }
  .heartAuthor {
    width: 84px;
  }
}

.dataTesti > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.thankyouPage {
  background-position: center;
}
@media only screen and (max-width: 768px) {
  body .whyChsWrp {
    padding: 25px;
    margin: 0 auto;
   
  }
  .customhome video {
    height: auto;
  }
  .dataTesti > img {
    width: 60px;
    height: 60px;
  }
  body .slideronenew {
    width: 92%;
    margin: 0 auto;
    padding: 15px 0px;
  }
  .heartAuthor {
    top: 32px;
    right: 0px;
    width: 67px;
    position: absolute;
  }
  .testimonial p.fs16 {
    font-size: 12px;
  }
  .dataTesti {
    padding-top: 103px;
  }
  .fs15 {
    font-size: 13px;
  }
  p {
    font-size: 14px;
  }
}


@media only screen and (max-width: 767px) {
  body .bannerF {
    font-size: 16vw;
    line-height: 9vh;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible {
    cursor: pointer;
    height: auto;
    width: auto !important;
  }
  .homeBanner .bannerImg {
    /* height: 510px; */
    object-fit: cover;
  }
  body .swiperThumbnail .swiper-wrapper{
    width: 35%;
  }
  /* body .swiperThumbnail .swiper-slide.swiper-slide-visible {
    width: auto !important;
  } */
  body .mainProSl img {
    height: auto;
    object-fit: cover;
  }
  body .living {
    font-size: 80px;
    line-height: 70px;
  }
  .fs40 {
    font-size: 22px;
  }
  body .fs70 {
    font-size: 41px;
  }
  body .swiperThumbnail .swiper-wrapper img {
    width: 45px;
  }
  body .circleAnimate {
    width: 250px;
    height: 250px;
  }
  .mobPlr0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .test_wrapper .dataTesti {
    position: static !important;
    width: 100%;
  }
  .dataTesti {
    width: 100%;
  }
  .test_wrapper .homeFrameImg {
    display: none;
  }
  h2 br {
    display: none;
  }
  body .fs58 {
    font-size: 33px;
  }
  .copyright * {
    font-size: 12px;
    margin-top: 0px !important;
  }
  .copyright {
    margin-top: 0px !important;
  }
  body .viewToggle img{
    width: 62px;
  }
  body .wrapperContact {
    padding: 15px;
  }
  
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) {
    width: 62px !important;
    margin-top: 4px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) img{
    width: 62px !important;
   
  }
  .heartAuthor {
    top: 30px;
    right: 0px;
    width: 87px;
    position: absolute;
  }
  .dataTesti {
    padding-top: 125px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 380px) {
  body .swiperThumbnail .swiper-wrapper{
    width: 40%;
  }
}


.titles {
  position: absolute;
  z-index: 1;
  bottom: 40px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: 8e7653 #f6f3f0;
}
.blogimage {
  height: 256px !important;
  object-fit: cover;
}
.border-radius {
  border-radius: 16px;
}
.bigfont {
  position: sticky;
  top: 80px;
  z-index: -1;
}
.bigfont h1 {
  font-size: 190px;
  text-align: left;
  position: absolute;
  left: 0px;
  color: transparent;
  -webkit-text-stroke: 2px #b88b4c;
  z-index: -1;
  font-family: para;
  line-height: 201px;
  top: 70px;
  opacity: 0.2;
}
.font-weight-bold {
  font-weight: bold;
}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #8e7653;
}
*::-webkit-scrollbar-thumb {
  background-color: #8e7653;
  border: 3px solid #f6f3f0;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #8e7653;
  border: 3px solid #f6f3f0;
}

.crcWrapper {
  width: 70px;
  height: 70px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crcWrapper img {
  width: 28px;
}
.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
  content: '' !important;
  background: url('./assets/img/home/arr.png') no-repeat;
  background-size: 65px !important;
  z-index: 1;
  position: absolute;
  width: 100px;
  height: 100px;
  right: 0px;
  top: 100%;
}
.swiper-button-next, .swiper-button-prev {
  align-items: flex-end !important;
}
.swiper-button-next, .swiper-button-prev {
  align-items: flex-end !important;
  transform: translate(-20px,230px);
}
.testimonialSlider {
  padding-bottom: 60px !important;
}
.swiper-button-prev {
  display: none !important;
}


/*project hover*/

.content_wrp {
  position: relative;
  margin: auto;
  overflow: hidden;
}
.content_wrp .content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content_wrp:hover .content-overlay{
  opacity: 1;
}

.content-image{
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content_wrp:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3{
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p{
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom{
  top: 80%;
}

/*project hover*/
.topDown {
  position: fixed !important;
  top: 0px !important;
  z-index: 3 !important;
}

/*hover effect*/
.button {
	pointer-events: auto;
	cursor: pointer;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	position: relative;
	display: inline-flex;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  border-color: #000 !important;
}
.button--calypso {
	overflow: hidden;
}

.button--calypso span {
	display: block;
	position: relative;
	z-index: 10;
}

.button--calypso:hover span {
	animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
  color: #fff;
  border-color: #000 !important;
}


@keyframes MoveScaleUpInitial {
	to {
		transform: translate3d(0,-105%,0) scale3d(1,2,1);
		opacity: 0;
	}
}

@keyframes MoveScaleUpEnd {
	from {
		transform: translate3d(0,100%,0) scale3d(1,2,1);
		opacity: 0;
	}
	to {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
}

.button--calypso::before {
	content: '';
	background: #000;
	width: 120%;
	height: 0;
	padding-bottom: 120%;
	top: -110%;
	left: -10%;
	border-radius: 50%;
	transform: translate3d(0,68%,0) scale3d(0,0,0);
  border-color: #000 !important;
}

.button--calypso:hover::before {
	transform: translate3d(0,0,0) scale3d(1,1,1);
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  border-color: #000 !important;
}

.button--calypso::after {
	content: '';
	background: #000;
	transform: translate3d(0,-100%,0);
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  border-color: #000 !important;
}

.button--calypso:hover::after {
	transform: translate3d(0,0,0);
	transition-duration: 0.05s;
	transition-delay: 0.4s;
	transition-timing-function: linear;
  border-color: #000 !important;
}


/*hover effect*/
.circleAnimate.hideRotate::before{
  display: none;
}
.darkMode{
  background: #070708;
}
.bgBlueGradient{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#070707+0,010733+100 */
background: linear-gradient(to bottom,  #070707 0%,#010733 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

/*slide reveal effect*/
 
:root {
	--delay: 500ms;
	--duration: 800ms;
	--iterations: 1;
}
.reveal-text,
.reveal-text::after {
	animation-delay: var(--animation-delay, 2s);
	animation-iteration-count: var(--iterations, 1);
	animation-duration: var(--duration, 800ms);
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.reveal-text {
	--animation-delay: var(--delay, 0);
	--animation-duration: var(--duration, 800ms);
	--animation-iterations: var(--iterations, 1);
	position: relative;
	animation-name: clip-text;
	
	&::after {
		content: "";
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #222222;
		transform: scaleX(0);
		transform-origin: 0 50%;
		pointer-events: none;
		animation-name: text-revealer;
	}
	
}


@keyframes clip-text {
	from {
		clip-path: inset(0 100% 0 0);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}


@keyframes text-revealer {
	
	0%, 50% {
		transform-origin: 0 50%;
	}
	
	60%, 100% {
		transform-origin: 100% 50%;		
	}

	
	60% {
		transform: scaleX(1);
	}
	
	100% {
		transform: scaleX(0);
	}
}
/*slide reveal effect*/
.leftBorder::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  height: 100%;
  background: #000;

}

.boxFlip{
  background: #ddd;
  height: 50vh;
  width: 100%;
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  margin-top: 30vh;
}

/*flip*/



/*-=-=-=-=-=-=-=-=-=-=- */
/* Flip Panel */
/*-=-=-=-=-=-=-=-=-=-=- */



.panel {
	margin: 0 auto;
	height: 100vh;  
	position: relative;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
}

.panel .front,
.panel .back {
	text-align: center;
  width: 100%;
}
	
.panel .front {
	height: inherit;
	position: absolute;
	top: 0;
	z-index: 900;
	text-align: center;
	-webkit-transform: rotateX(0deg) rotateY(0deg);
	   -moz-transform: rotateX(0deg) rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	-webkit-transition: all .6s ease-in-out;
	   -moz-transition: all .6s ease-in-out;
		-ms-transition: all .6s ease-in-out;
		 -o-transition: all .6s ease-in-out;
			transition: all .6s ease-in-out;
}

.panel .back {
	height: inherit;
	position: absolute;
	top: 0;
	z-index: 1000;
	-webkit-transform: rotateY(-180deg);
	   -moz-transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	-webkit-transition: all .7s ease-in-out;
	   -moz-transition: all .7s ease-in-out;
		-ms-transition: all .7s ease-in-out;
		 -o-transition: all .7s ease-in-out;
			transition: all .7s ease-in-out;
}
.loader.containerAnimate .panel .front {
	z-index: 900;
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}
.loader.containerAnimate .panel .back {
	z-index: 1000;
	-webkit-transform: rotateX(0deg) rotateY(0deg);
	-moz-transform: rotateX(0deg) rotateY(0deg);
}
.box1{
	background-color: #eeeeee;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	padding: 20px;
  /* border: 1px solid var(--colorBrown); */
}
.box2{
	background-color: #eeeeee;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	padding: 20px;
  /* border: 1px solid var(--colorBrown); */
}
.sectionAnimate {
  height: 100vh;
}
.plusIcon {
  position: relative;
  top: 9px;
}
.swiperFadeEff .swiper-slide {
  opacity: 0 !important;
}
.swiperFadeEff .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

/* .fullHeight{
  min-height: 75vh;
}  */

.blankData{
  min-height: 200px;
}
.scrollHide{
  display: none;
}
.swiper-pagination-bullet{
  position: relative;
}
.swiper-pagination-bullet {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 16px 0 16px 29px;
  border-color: transparent transparent transparent #FFFFFF;
  transform: rotate(0deg);
  background: transparent !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}
.swiper-pagination {
  text-align: right !important;}
  .swiper-pagination-bullet:nth-child(1) {
    position: relative;
    left: 17px;
}
.swiper-pagination-bullet:nth-child(3) {
    position: relative;
    right: 18px;
}
.swiper-pagination-bullet:nth-child(4) {
  position: relative;
  right: 33px;
}
.swiper-pagination-bullet:nth-child(5) {
  position: relative;
  right: 50px;
}
.swiper-pagination-bullet:nth-child(6) {
  position: relative;
  right: 68px;
}

.slideronenew .swiper-pagination {
  position: relative;
  display: flex;
  justify-content: end;
  margin-left: 26px;
  top: 15px !important;
  padding-bottom: 16px;
}
/*tst*/




html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}

.service {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 100%;
    position: relative;
    min-height: 100vh;
}

/* .first_service.service {
  display: none;
} */
.services {
  /* display: flex; */
  /* flex-direction: column; */
  overflow: hidden;
}

.service {
  min-width: 100%;
}

.service h1 {
  color: #000;
  font-size: 40px;
  padding: 70px 0;
}

@media screen and (min-width: 768px) {
  .services {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    height: 100vh;
  }


}
/* .pin-spacer {
  padding-bottom: 0px !important;
} */
/*tst*/


.swiperThumbnail .swiper-wrapper {
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
  background-position:right;
  background-repeat: no-repeat;
  background-size: contain;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 0 !important;
  max-width: 36%;
}

@media only screen and (min-width: 1024px) {
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(1) {
    position: relative;
    left: 97px;
    top: 15px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(2) {
    position: relative;
    left: -132px;
    top: 50px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(3) {
    position: relative;
    left: -100px;
    top: 110px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) {
    position: relative;
    left: 0px;
    top: 10px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(5) {
    position: relative;
    left: 0px;
    top: 33px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(6) {
    position: relative;
    left: 45px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(7) {
    position: relative;
    left: 45px;
    top: -10px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(8) {
    position: relative;
    left: 0px;
    top: 40px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(2) {
    position: relative;
    left: -160px;
    top: 80px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) {
    width: 162px !important;
    margin-top: 25px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(6) {
    position: relative;
    left: 20px;
    top:-20px;
  }
}

@media only screen and (max-width: 767px) {
 
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(2) {
    position: relative;
    left: -108px;
    top: 21px;
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(4) {
    position: relative;
    /* left: -22px; */
  }
  .swiperThumbnail .swiper-slide.swiper-slide-visible:nth-child(3) {
    position: relative;
    left: 20px;
    /* top: 110px; */
  }
 
  .crcWrapper {
    width: 53px;
    height: 53px;
  }
  .crcWrapper img {
    width: 20px;
  }
 
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: left !important;
}
.carousel-status {
  display: none;
}
.aniCl {
  position: absolute;
  width: auto ;
  height: 100%;
  color: #000 !important;
  background: #000 !important;
  z-index: 3;
}
.vHidden{
  visibility: hidden;
}
.video-react-controls-enabled video {
  width: 100% !important;
  object-fit: cover;
  height: 100vh;
}
.video-react-loading-spinner,.video-react-button,.video-react-control-bar{
  display: none !important;
}
.video-react-controls-enabled{
  padding: 0px !important;
}
.z999{
  z-index: 999;
}
.visionvidio video {
  height: auto !important;
}

.rippleEffect {
  display: block;
  width: 30px;
  height: 30px;
  animation: ripple 0.6s linear infinite;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: rgba(204,205,205)
}
.triggerCircle{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: rgba(204,205,205);
  cursor: pointer;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(204,205,205, 0.1), 0 0 0 10px rgba(204,205,205, 0.1), 0 0 0 20px rgba(204,205,205, 0.1), 0 0 0 30px rgba(204,205,205, 0.1);
 }
  100% {
    box-shadow: 0 0 0 10px rgba(204,205,205, 0.1), 0 0 0 20px rgba(204,205,205, 0.1), 0 0 0 30px rgba(204,205,205, 0.1), 0 0 0 40px rgba(204,205,205, 0);
 }
}

.c2 {
  top: -15px;
}
.c3 {
  right: -15px;
}
.c4 {
  bottom: -15px;
}
.c1 {
  left: -15px;
}


.custom-div {
  position: relative;
  width: calc(100vw / 3 - 2px);
  height: 100vh;
  padding: 0;
  margin: -2px;
  overflow: hidden;
  outline: 1px white solid;
  cursor: none;
}
.custom-div .text {
  position: absolute;
  white-space: nowrap;
  background-color: transparent;
  font-size: 14px !important;
  display: none;
  pointer-events: none;
  color: #fff;
  font-size: 1.5rem;
  z-index: 999999;
  background: rgba(0,0,0,0.5);
  padding: 5px 10px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group{
  margin-bottom: 15px;
}
.form-group label {
  font-size: 14px;
  color: #6c6c6c;
  font-weight: 600;
  margin-bottom: 4px;
}
select.form-control {
  height: 47px;
}
.nightText *{
  color:  #ddd;
}
.nightForm input,.nightForm textarea {
  color: #ddd !important;
}
.nightForm select{
  color: #ddd ;
}
.nightText a{
  color: var(--colorBrown);
}
.nightForm button.btnTheme {
  background: var(--colorBrown);
}
.bgImageSlider {
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
}
.viewLink {
  z-index: 9;
}
.bgblog h4 {
  background-color: #09334a;
  padding: 5px;
  width: fit-content;
}
@media only screen and (max-width: 767px) {
  .bgImageSlider {
    height: 50vh;
  }
}

@media only screen and (max-width: 768px) {
  .bgImageSlider {
    height: 60vh;
  }
}
/*custome slider*/
.activeSlide{
  display: block;
}
.hideSlider{
  display: none;
}
/*custome slider*/
.darkMode .imgProject::after,.darkMode .imgProject2::after {
  background: #fff;
}
.nextText {
  position: absolute;
  bottom: 20px;
  right: 130px;
}
.nextText.testimonialsv {
  right: 240px;
}
.nextText {
  color: #fff;
}
.shake {
	animation: shake 3000ms ease-out infinite;
  display: block;
}
@keyframes shake {
	0% {
		transform: translateX(0);
	}
	5% {
		transform: translateX(-4px);
	}
	15% {
		transform: translateX(4px);
	}
	25% {
		transform: translateX(-4px);
	}
	35% {
		transform: translateX(4px);
	}
	45% {
		transform: translateX(-4px);
	}
	55% {
		transform: translateX(4px);
	}
	65% {
		transform: translateX(-4px);
	}
	75% {
		transform: translateX(3px);
	}
	85% {
		transform: translateX(-3px);
	}
	100% {
		transform: translateX(0);
	}
}

/* .thankyouPage::after {
  content: "";
  position: absolute;
  top: 0px;
  background: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
} */
.thankH{
  color: #fff;
}
.thankyouPage {
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .customhome video {
    height: auto;
  }
}